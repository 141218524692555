export const downloads = [
    {
        category: 'bafa',
        files: [
            {
                name: 'FörderProfi Vollmacht',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Vollmacht.pdf',
            },
            {
                name: 'Bestätigung zu den förderfähigen Investitionskosten',
                link:
                    'https://www.foerder-profi.de/downloads/FoerderProfi_Bestaetigung-zu-den-foerderfaehigen-Investitionskosten.pdf',
            },
            {
                name: 'Hydraulischer Abgleich',
                link:
                    'https://files.vdzev.de/pdfs/hydraulischer-abgleich-einzelmassnahme/VDZ_Formular_HydrAbgleich_Einzelmassnahme_BEG.pdf',
            },
        ],
    },
    {
        category: 'kfw',
        files: [
            {
                name: 'Checkliste Hilfestellung KfW BEG 2024',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Checkliste-Hilfestellung-KfW-BEG-2024.pdf',
            },
            {
                name: 'Ergänzende Angaben zur Maßnahme (Brennstoffzelle)',
                link:
                    'https://www.foerder-profi.de/downloads/FoerderProfi_Ergaenzende-Angaben-zur-Massnahme-Brennstoffzelle.pdf',
            },
            {
                name: 'FörderProfi Vollmacht',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Vollmacht_KfW.pdf',
            },
            {
                name: 'Hydraulischer Abgleich',
                link:
                    'https://files.vdzev.de/pdfs/hydraulischer-abgleich-einzelmassnahme/VDZ_Formular_HydrAbgleich_Einzelmassnahme_BEG.pdf',
            },
            {
                name: 'Fachunternehmererklärung (Brennstoffzelle)',
                link:
                    'https://www.foerder-profi.de/downloads/FoerderProfi_Fachunternehmererklaerung-Brennstoffzelle.pdf',
            },
            {
                name: 'Ausfüllhilfe Fachunternehmererklärung (Brennstoffzelle)',
                link:
                    'https://www.foerder-profi.de/downloads/FoerderProfi_Ausfuellhilfe-Fachunternehmererklaerung-Brennstoffzelle.pdf',
            },
            {
                name: 'Ausfüllhilfe Ergänzende Angaben zur Maßnahme',
                link:
                    'https://www.foerder-profi.de/downloads/FoerderProfi_Ausfuellhilfe-Ergeanzende-Angaben-zur-Massnahme.pdf',
            },
        ],
    },
    {
        category: 'general',
        files: [
            {
                name: 'Registrierungshilfe MaStR',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Registrierungshilfe-MaStR.pdf',
            },
            {
                name: 'Ergänzende Hinweise zur FörderProfi Vollmacht',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Ergänzende-Hinweise-zur-Vollmacht.pdf',
            },
            {
                name: 'Einwilligung des Eigentümers',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Einwilligung-des-Eigentuemers.pdf',
            },
            {
                name: 'Checkliste Hilfestellung BAFA-Anträge',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Checkliste-Hilfestellung-BAFA-Antraege.pdf',
            },
        ],
    },
];
